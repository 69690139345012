import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import i18n from '../../i18n';
import SettingsContext from 'context/SettingsContext';

class LanguageSelect extends React.Component {
  constructor(props) {
    super(props);
    const nextLng = localStorage.getItem('i18nextLng');

    this.state = {
      open: false,
      selectedLanguage: nextLng || 'en',
    };
  }

  componentDidMount() {
    this.context.setLanguage(this.state.selectedLanguage);
  }

  changeLanguage = selectedLanguage => {
    i18n.changeLanguage(selectedLanguage);

    this.setState({ selectedLanguage });
    this.context.setLanguage(selectedLanguage);
  };

  toggleDropdown = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  render() {
    const { selectedLanguage, open } = this.state;
    return (
      <ButtonDropdown isOpen={open} toggle={this.toggleDropdown}>
        <DropdownToggle caret size="sm">
          {selectedLanguage.toUpperCase()}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => this.changeLanguage('en')}>
            English
          </DropdownItem>
          <DropdownItem onClick={() => this.changeLanguage('es')}>
            Español
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

LanguageSelect.contextType = SettingsContext;

export default withTranslation()(LanguageSelect);
