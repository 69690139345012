/* eslint-disable react/prefer-stateless-function */
import React from 'react';

class DatePickerButton extends React.Component {
  render() {
    const { disabled } = this.props;
    return (
      <button
        type="button"
        className="btn btn-default btn-outline"
        onClick={this.props.onClick}
        disabled={disabled}
      >
        {this.props.value || this.props.defaultText}
      </button>
    );
  }
}

DatePickerButton.defaultProps = {
  defaultText: 'Click here to pick date',
};

export default DatePickerButton;
