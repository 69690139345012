/* eslint-disable consistent-return */
import auth0 from 'auth0-js';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { setCookie, deleteCookie } from 'services/api/auth/cookie';
import * as settings from './settings';

class Auth {
  auth0 = new auth0.WebAuth({
    domain: settings.domain,
    clientID: settings.clientID,
    responseType: 'token id_token',
    scope: 'openid',
    audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
    redirectUri: `${process.env.REACT_APP_APP_URL}`,
  });

  accessToken;

  idToken;

  expiresAt;

  isValid;

  handleAuthentication = () =>
    new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);

        if (!authResult || !authResult.idToken || !authResult.accessToken) {
          return reject(err);
        }

        this.setSession(authResult).then(() => resolve());
      });
    });

  isAuthenticated = () => {
    const currentTime = new Date().getTime();
    return currentTime < this.expiresAt;
  };

  login = (options, realm, errorCb) => {
    this.auth0.login(
      {
        ...options,
        realm,
      },
      errorCb
    );
  };

  logout = () => {
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;
    deleteCookie();
    localStorage.removeItem('isLoggedIn');
  };

  getAccessToken = () => this.accessToken;

  setSession = async ({ expiresIn, accessToken, idToken }) => {
    if (localStorage) localStorage.setItem('isLoggedIn', '1');
    await setCookie(accessToken);
    this.accessToken = accessToken;
    this.idToken = idToken;
    this.expiresAt = expiresIn * 1000 + new Date().getTime();
  };

  renewSession = () =>
    new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        this.setSession(authResult).then(() => resolve());
      });
    });

  _getJWK = async () => {
    const res = await axios.get(
      `https://${settings.domain}/.well-known/jwks.json`
    );

    return res.data;
  };

  _verifyToken = async token => {
    if (token) {
      const decodedToken = jwt.decode(token, { complete: true });
      const jwk = await this._getJWK();
      let cert = jwk.keys[0].x5c[0];
      cert = cert.match(/.{1,64}/g).join('\n');
      cert = `-----BEGIN CERTIFICATE-----\n${cert}\n-----END CERTIFICATE-----\n`;

      if (jwk.keys[0].kid === decodedToken.header.kid) {
        try {
          jwt.verify(token, cert);
          return true;
        } catch (error) {
          // console.error(error);
          return false;
        }
      }
    }

    return false;
  };
}

const Auth0Service = new Auth();

export default Auth0Service;
