import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getAllCountries from 'services/api/country/getAllCountries';

interface Country {
  name: string;
  countryCode: string;
  isBusinessProfileAvailable: boolean;
  countryCodeAlpha3: string;
  callingCode: string;
  subdivisions: CountryState[];
  productionActivities: BusinessIndustry[];
  civilStatus: CivilStatus[];
  parentageStatus: Relationship[];
}

interface CountryNames {
  [countryCode: string]: string;
}

interface ContextData {
  countries: Country[];
  businessCountries: Country[];
  countryNames: CountryNames;
  getCountryNameByCode: (code: string) => string;
}

const CountriesContext = React.createContext<ContextData>({
  countries: [],
  businessCountries: [],
  countryNames: {},
  getCountryNameByCode: () => '--',
});

export const CountriesProvider: React.FC = ({ children }) => {
  const [countries, setCountries] = useState([] as Country[]);
  const [countryNames, setCountryNames] = useState({} as CountryNames);
  const [businessCountries, setBusinessCountries] = useState([] as Country[]);
  const { i18n } = useTranslation();

  useEffect(() => {
    let cancel = false;

    const getCountries = async () => {
      const countryList = (await getAllCountries(i18n.language)).data
        .data as Country[];
      if (cancel) return;
      setCountries(countryList);
      setBusinessCountries(
        countryList.filter((c: Country) => c.isBusinessProfileAvailable)
      );
      setCountryNames(
        countryList.reduce(
          (acc: CountryNames, { name, countryCode }) => ({
            ...acc,
            [countryCode]: name,
          }),
          {}
        )
      );
    };
    getCountries();

    return () => {
      cancel = true;
    };
  }, [i18n.language]);

  const getCountryNameByCode = (code: string): string => {
    const country = countries.find(r => r.countryCode === code);
    return country ? country.name : '--';
  };

  return (
    <CountriesContext.Provider
      value={{
        businessCountries,
        countries,
        countryNames,
        getCountryNameByCode,
      }}
    >
      {children}
    </CountriesContext.Provider>
  );
};

export const useCountries = () => useContext(CountriesContext);

export default CountriesContext;
