import React, { useEffect, useState, useContext, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/remote-config';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
const defaultFeatureFlags: FeatureFlags = {
  featureFlagStp: false, 
  featureFlagBanregio: false,
  featureFlagTransfersEnabled: false,
  featureFlagTransfersMxEnabled: false,
  featureFlagReadOnly:false,
};

const FETCH_REMOTE_CONFIG_INTERVAL = 780000; // 13 minutes

const SettingsContext = React.createContext<SettingsContext>({
  featureFlags: defaultFeatureFlags,
  language: 'en',
  setLanguage: () => {},
});
SettingsContext.displayName = 'SettingsContext';

const mapRemoteConfigsToFeatureFlags = (
  remoteConfigs: FirebaseRemoteConfigs
): FeatureFlags => {
  return {
    featureFlagStp: remoteConfigs.feature_flag_stp_enabled
      ? remoteConfigs.feature_flag_stp_enabled.asBoolean()
      : defaultFeatureFlags.featureFlagStp,
    featureFlagBanregio: remoteConfigs.feature_flag_banregio_enabled
      ? remoteConfigs.feature_flag_banregio_enabled.asBoolean()
      : defaultFeatureFlags.featureFlagBanregio,
    featureFlagTransfersEnabled: remoteConfigs.feature_flag_transfers_enabled
      ? remoteConfigs.feature_flag_transfers_enabled.asBoolean()
      : defaultFeatureFlags.featureFlagTransfersEnabled,
    featureFlagTransfersMxEnabled: remoteConfigs.feature_flag_transfers_mx_enabled
      ? remoteConfigs.feature_flag_transfers_mx_enabled.asBoolean()
      : defaultFeatureFlags.featureFlagTransfersMxEnabled,
    featureFlagReadOnly: remoteConfigs.featureFlagReadOnly
      ? remoteConfigs.featureFlagReadOnly.asBoolean()
      : defaultFeatureFlags.featureFlagReadOnly,
  };
};

export const SettingsProvider: React.FC = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState(defaultFeatureFlags);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    if (!firebase.apps.length) {
      try {
        firebase.initializeApp(firebaseConfig);
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }, []);

  const fetchRemoteConfigs = useCallback(async () => {
    try {
      const remoteConfig = firebase.remoteConfig();
      remoteConfig.settings = {
        fetchTimeoutMillis: 3600000,
        minimumFetchIntervalMillis: FETCH_REMOTE_CONFIG_INTERVAL,
      };
      await remoteConfig.fetchAndActivate();
      const remoteConfigs = remoteConfig.getAll();
      setFeatureFlags(mapRemoteConfigsToFeatureFlags(remoteConfigs));
    } catch {
      // Do nothing and keep using the previous values
    }
    setTimeout(fetchRemoteConfigs, FETCH_REMOTE_CONFIG_INTERVAL);
  }, []);

  useEffect(() => {
    fetchRemoteConfigs();
  }, [fetchRemoteConfigs]);

  return (
    <SettingsContext.Provider value={{ featureFlags, language, setLanguage }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useFeatureFlags = () => useContext(SettingsContext).featureFlags;
export const useLanguage = () => [
  useContext(SettingsContext).language,
  useContext(SettingsContext).setLanguage,
];

export default SettingsContext;
