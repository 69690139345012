import axios from 'axios';
import defaultConfig from './config';

const rq = axios.create(defaultConfig);
const requestHandler = {
  get: <T>(url: string, params = {}, config = {}) =>
    rq.request<ApiData<T>>({
      url,
      method: 'get',
      params,
      ...config,
    }),
  post: <T>(url: string, params = {}, config = {}) =>
    rq.request<ApiData<T>>({
      url,
      method: 'post',
      data: params,
      ...config,
    }),
  put: <T>(url: string, params = {}, config = {}) =>
    rq.request<ApiData<T>>({
      url,
      method: 'put',
      data: params,
      ...config,
    }),
  patch: <T>(url: string, params = {}, config = {}) =>
    rq.request<ApiData<T>>({
      url,
      method: 'patch',
      data: params,
      ...config,
    }),
  delete: <T = undefined>(url: string, params = {}, config = {}) =>
    rq.request<ApiData<T>>({
      url,
      method: 'delete',
      data: params,
      ...config,
    }),
  head: <T = undefined>(url: string, params = {}, config = {}) =>
    rq.request<ApiData<T>>({
      url,
      method: 'head',
      data: params,
      ...config,
    }),
};

export default requestHandler;
