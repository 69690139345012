import { lazy } from 'react';

const UserDetailsPage = lazy(() => import('./UserDetail'));
const MovementsPage = lazy(() => import('./Movements'));
const PendingMovementsPage = lazy(() => import('./PendingMovements'));
const PendingCardRequestsPage = lazy(() => import('./PendingCardRequests'));
const AdvisorsPage = lazy(() => import('./Advisors'));
const BankAccounts = lazy(() => import('./DirectDebit/BankAccounts'));
const PendingDeposits = lazy(() => import('./DirectDebit/PendingDeposits'));
const Documents = lazy(() => import('./Documents'));
const SearchProfileProducts = lazy(() => import('./SearchProfileProducts'));
const PromotionCodesPage = lazy(() => import('./PromotionCodes'));

export {
  UserDetailsPage,
  MovementsPage,
  PendingMovementsPage,
  PendingCardRequestsPage,
  AdvisorsPage,
  BankAccounts,
  PendingDeposits,
  Documents,
  SearchProfileProducts,
  PromotionCodesPage,
};
