import { EnumHelper } from 'utils/helpers';

const MovementStatus = {
  PENDING: {
    value: 'PENDING',
  },
  TO_BE_PROCESSED: {
    value: 'TO_BE_PROCESSED',
  },
  COMPLETED: {
    value: 'COMPLETED',
  },
  CANCELLED: {
    value: 'CANCELLED',
  },
  INITIATED: {
    value: 'INITIATED',
  },
  REVERSED: {
    value: 'REVERSED',
  },
  PENDING_AUTHORIZATION: {
    value: 'PENDING_AUTHORIZATION',
  },
};

export const MovementStatusEnumHelper = new EnumHelper(
  MovementStatus,
  'movementStatus'
);

export default MovementStatus;
