import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import theme from 'config/theme';
import Toast from 'components/Toast';
import history from './history';

import 'react-datepicker/dist/react-datepicker.css';

// global css
import './css/bootstrap/scss/bootstrap.css';
import './css/main.css';
import './css/ui-elements/typography.css';
import './css/ui-elements/cards.css';
import './css/ui-elements/buttons.css';
import './css/icons/simple-line-icons.css';

import Spinner from './components/UI/Spinner';

import Routes from './Routes';
import { SettingsProvider } from 'context/SettingsContext';

const App = () => (
  <ThemeProvider theme={theme}>
    <SettingsProvider>
      <ConnectedRouter history={history}>
        <React.Suspense fallback={<Spinner visible />}>
          <Spinner />
          <Toast />
          <Routes />
        </React.Suspense>
      </ConnectedRouter>
    </SettingsProvider>
  </ThemeProvider>
);

export default App;
