import React from 'react';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import '../css/elements/logo.css';
import { Flex, Box } from 'components/grid';
import WalletLogo from 'icons/WalletLogo';
import { Text } from 'components/typography';

const LogoContainer = () => (
  <Link to="/" className="logo"  style={{ backgroundColor: '#303030' }}>
    <Flex justifyContent="center" alignItems="center" >

      <Text fw="700">Operations</Text>
    </Flex>
  </Link>
);
export default LogoContainer;
