import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Auth0Service from 'services/auth/Auth0Service';
import AppLayout from 'layouts/AppLayout';
import Spinner from 'components/UI/Spinner';
import { logout } from 'actions/authActionCreators';

class ProtectedRoute extends React.Component {
  state = {
    // redirect: false,
  };

  async componentDidMount() {
    const { isAuthenticated } = Auth0Service;

    if (localStorage.getItem('isLoggedIn') === '1' && !isAuthenticated()) {
      try {
        await Auth0Service.renewSession();
        this.forceUpdate();
      } catch (err) {
        window.console.log(err);
        this.props.logout();
      }
    }
    // if (!isAuthenticated()) this.setState({ redirect: true });
    if (!isAuthenticated()) this.props.logout();
  }

  render() {
    // const { redirect } = this.state;

    // if (redirect) return <Redirect to="/login" />;
    const { isAuthenticated } = Auth0Service;

    const { component: Component, ...rest } = this.props;

    return (
      <>
        {isAuthenticated() ? (
          <Route
            {...rest}
            render={props => (
              <AppLayout>
                <Component {...props} />
              </AppLayout>
            )}
          />
        ) : (
          <Spinner visible />
        )}
      </>
    );
  }
}

const actions = {
  logout,
};

export default connect(
  null,
  actions
)(ProtectedRoute);
