import React from 'react';
import styled from 'styled-components';

import Wikiquote from '../../utils/helpers/Wikiquote';

export default class extends React.Component {
  state = {
    quote: '',
    author: '',
  };

  componentDidMount() {
    this.getQuote();
  }

  getQuote = () => {
    const authors = [
      'Mahatma Gandhi',
      'Albert Einstein',
      'Martin Luther King, Jr.',
      'Leonardo da Vinci',
      'Walt Disney',
      'Edgar Allan Poe',
      'Sigmund Freud',
      'Thomas A. Edison',
      'Robin Williams',
      'Steve Jobs',
    ];
    const randomInt = (max, min) =>
      Math.floor(Math.random() * (max - min + 1)) + min;
    Wikiquote.getRandomQuote(
      authors[randomInt(0, authors.length)],
      q => {
        // console.log('selected quote', q.quote);
        if (q.quote && q.quote.length <= 180) {
          this.setState({ quote: q.quote, author: q.titles });
        } else {
          this.getQuote();
        }
      },
      this.getQuote
    );
  };

  render() {
    const { quote, author } = this.state;
    const show = quote ? 1 : 0;
    return (
      <NoNameCont show={show}>
        <QuotesGeneratorContainer>
          <IconContainer className="icons">
            <i className="fa fa-quote-left" />
          </IconContainer>

          <QuoteContainer>
            <p>{quote}</p>
          </QuoteContainer>
        </QuotesGeneratorContainer>
        <AuthorContainer> ~ {author}</AuthorContainer>
      </NoNameCont>
    );
  }
}
const NoNameCont = styled.div`
  max-width: 400px;
  margin: auto;
  opacity: ${props => props.show};
  transition: opacity 1s;
`;
const QuotesGeneratorContainer = styled.div`
  display: table;
  width: 100%;
`;
const IconContainer = styled.div`
  display: table-cell;
  /* width: 10%; */
  /* text-align: right; */
`;
const QuoteContainer = styled.div`
  display: table-cell;
  padding-left: 10px;
  /* width: 90%; */
  /* text-align: center; */
`;

const AuthorContainer = styled.div`
  text-align: right;
`;
