import styled from 'styled-components';

import { color, fontSize, fontWeight, fontFamily } from 'config/style';
import { Box } from 'components/grid';

const Text = styled(Box)`
  ${color};
  ${fontSize};
  ${fontWeight};
  ${fontFamily};
`;

export default Text;
