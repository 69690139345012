import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import history from './history';
import createRootReducer from './reducers/index';
import { authInitialState } from './reducers/authReducer';
import { userInitialState } from './reducers/userReducer';

import { getKeys } from './utils/helpers/storageManager';

let composeEnhancers = compose;

if (process.env.NODE_ENV !== 'production')
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const authReducerProperties = getKeys('token');

const authReducerPreloadState = {
  ...authInitialState,
  ...authReducerProperties,
};

let userReducerProperties = getKeys(
  'userId',
  'email',
  'name',
  'profileId',
  'partner',
  'referenceCode',
  'role',
  'advisorId',
  'firstName',
  'lastName',
  'language',
  'createdAt',
  'updatedAt'
);

const permissions = (getKeys('permissions').permissions || '')
  .split(',')
  .filter(x => x);

userReducerProperties = {
  ...userReducerProperties,
  permissions,
};

const userReducerPreloadState = {
  ...userInitialState,
  ...userReducerProperties,
};

const storePreloadedState = {
  auth: authReducerPreloadState,
  user: userReducerPreloadState,
};

export default function configureStore() {
  const store = createStore(
    createRootReducer(history),
    storePreloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  );
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  //
  // store.subscribe(() => {
  //   console.log('store changed', store.getState())
  // })
  return store;
}
