const apiHost = process.env.REACT_APP_API_HOST;
const version = process.env.REACT_APP_APP_VERSION || 'unknown';

const defaultConfig = {
  baseURL: apiHost,
  withCredentials: true,
  headers: {
    'X-APPLICATION-NAME': 'fred',
    'X-APPLICATION-VERSION': version,
  },
};

export default defaultConfig;
