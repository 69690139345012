import React from 'react';
import i18n from '../../i18n';

export default class {
  constructor(values, translationKey) {
    this.values = values;
    this.translationKey = translationKey;
  }

  /**
   * @returns {Array} Returns an array of the supplied values on the shape of [{value:'value', text:'text', ...otherProperties}, {...}]. If translation key is present, will take text from i18n translations, else, text will be the value itself. This is mostly used to fill select dropdowns.
   */
  getValuesArray() {
    return Object.keys(this.values).map(k => {
      const current = this.values[k];
      return {
        ...current,
        text: this.getTextFromValue(current.value),
      };
    });
  }

  getOptions(optionKey) {
    return this.getValuesArray().map(l => (
      <option key={`${optionKey}-${l.value}`} value={l.value}>
        {l.text}
      </option>
    ));
  }

  /**
   * Gets the text related to the supplied value in the enum. If translation key is present, will take text from i18n translations.
   * @param {any} value
   */
  getTextFromValue(value) {
    return this.translationKey
      ? i18n.t(`${this.translationKey}.${value}`, value)
      : value;
  }
}
