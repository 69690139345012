import React from 'react';

import { Switch, Route } from 'react-router-dom';

import {
  UserDetailsPage,
  MovementsPage,
  PendingMovementsPage,
  PendingCardRequestsPage,
  AdvisorsPage,
  BankAccounts,
  PendingDeposits,
  Documents,
  PromotionCodesPage,
  // SearchProfileProducts,
} from 'pages/Admin';
import {
  LoginMain,
  Dashboard,
  TermsAndConditionsOfUse,
  LoggedId,
} from './pages';
import { WalletUsers } from './pages/Investment';
import ProtectedRoute from './components/HOC/ProtectedRoute';

const Routes = () => (
  <Switch>
    <ProtectedRoute
      path="/users/edit/:userId/:tab"
      component={UserDetailsPage}
    />

    <ProtectedRoute path="/movements" component={MovementsPage} />
    <ProtectedRoute
      path="/pending-movements"
      component={PendingMovementsPage}
    />
    <ProtectedRoute path="/users" component={WalletUsers} />
    {/*<ProtectedRoute*/}
    {/*  path="/profile-products"*/}
    {/*  component={SearchProfileProducts}*/}
    {/*/>*/}
    <ProtectedRoute
      path="/pending-card-requests"
      component={PendingCardRequestsPage}
    />
    <ProtectedRoute path="/promotion-codes" component={PromotionCodesPage} />
    <ProtectedRoute path="/advisors" component={AdvisorsPage} />
    <ProtectedRoute path="/bank-accounts" component={BankAccounts} />
    <ProtectedRoute path="/pending-deposits" component={PendingDeposits} />
    <ProtectedRoute path="/documents/:tab" component={Documents} />

    <Route path="/terms" component={TermsAndConditionsOfUse} />

    <Route path="/login" component={LoginMain} />
    <Route path="/user-registration" component={LoggedId} />
    <ProtectedRoute path="/dashboard" component={Dashboard} />

    <Route path="/" component={LoggedId} />
  </Switch>
);

export default Routes;
