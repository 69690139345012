import MovementStatus from 'utils/constants/MovementStatus';

export const Roles = {
  ADVISOR: 'advisor',
  OPERATIONS: 'operations',
};

const urls = [
  {
    title: 'Navigation',
    items: [
      {
        pathname: '/dashboard',
        url: '/dashboard',
        icon: 'sli-home',
        title: 'Dashboard',
        private: false,
        mandatoryPermissions: [],
        permissions: [],
        items: [],
      },
      {
        pathname: '/users',
        url: '/users',
        title: 'Users',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS, Roles.ADVISOR],
        items: [],
        icon: 'sli-people',
      },
      {
        title: 'Documents',
        private: false,
        icon: 'sli-docs',
        items: [
          {
            pathName: `/documents/proof-of-address`,
            url: `/documents/proof-of-address?status~in=PENDING`,
            title: 'Proof Of Address',
            private: false,
            permissions: [Roles.OPERATIONS],
          },
          {
            pathName: `/documents/beneficiary-banks`,
            url: `/documents/beneficiary-banks?status~in=PENDING`,
            title: 'Bank Details',
            private: false,
            permissions: [Roles.OPERATIONS],
          },
          {
            pathName: `/documents/kyc`,
            url: `/documents/kyc?kycStatus~in=PENDING`,
            title: 'KYC',
            private: false,
            permissions: [Roles.OPERATIONS],
          },
        ],
      },
      // {
      //   pathname: '/profile-products',
      //   url: '/profile-products?profileProductStatus~in=PENDING',
      //   title: 'Products',
      //   private: false,
      //   mandatoryPermissions: [],
      //   permissions: [Roles.OPERATIONS, Roles.ADVISOR],
      //   items: [],
      //   icon: 'sli-diamond',
      // },
      // Hided the Promotion Code from menu
      // {
      //   pathname: '/promotion-codes',
      //   url: '/promotion-codes',
      //   title: 'Promotion Codes',
      //   private: false,
      //   mandatoryPermissions: [],
      //   // TODO: Check the ROLES that will be added to the permissions array
      //   permissions: [Roles.OPERATIONS],
      //   items: [],
      //   icon: 'sli-present',
      // },
      /*{
        pathname: '/promotion-codes',
        url: '/promotion-codes',
        title: 'Promotion Codes',
        private: false,
        mandatoryPermissions: [],
        // TODO: Check the ROLES that will be added to the permissions array
        permissions: [Roles.OPERATIONS],
        items: [],
        icon: 'sli-present',
      },*/
      {
        pathName: '/advisors',
        url: '/advisors',
        title: 'Advisors',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS],
        items: [],
        icon: 'sli-organization',
      },
      {
        pathname: '/movements',
        url: '/movements',
        title: 'Movements',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS, Roles.ADVISOR],
        items: [],
        icon: 'sli-wallet',
      },
      {
        pathname: `/pending-movements`,
        url: `/pending-movements?status~in=${[
          MovementStatus.PENDING.value,
          MovementStatus.TO_BE_PROCESSED.value,
        ].join(',')}`,
        title: 'Pending Movements',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS],
        items: [],
        icon: 'sli-hourglass',
      },
      /*{
        pathName: `/pending-card-requests`,
        url: `/pending-card-requests`,
        title: 'Pending Card Requests',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS],
        items: [],
        icon: 'sli-credit-card',
      },*/
      {
        icon: 'sli-wallet',
        title: 'Direct Debit',
        private: false,
        items: [
          {
            pathname: '/bank-accounts',
            url: '/bank-accounts?status~in=PENDING',
            title: 'Bank Accounts',
            private: false,
            permissions: [Roles.OPERATIONS],
          },
          {
            pathname: '/pending-deposits',
            url: '/pending-deposits?status~in=PENDING',
            title: 'Pending deposits',
            private: false,
            permissions: [Roles.OPERATIONS],
          },
        ],
      },
      {
        pathname: '/terms',
        url: '/terms',
        icon: 'sli-note',
        title: 'Terms & Conditions',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS],
        items: [],
      },
    ],
  },
];
// eslint-disable-next-line import/prefer-default-export
export function navigation(state = Array.from(urls), action) {
 //const { featureFlags: { featureFlagStp } } = useContext(SettingsContext);

  switch (action.type) {
    case 'SET_NAVIGATION':
      return Object.assign({}, state, {
        ...action.navigation,
      });
    default:
      return state;
  }
}
