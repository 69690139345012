import { ALERT_MODAL, SPINNER, SHOW_MODAL } from './types';

export const alertModal = (
  show,
  onConfirm = null,
  onCancel = null,
  alertText = ''
) => ({
  type: ALERT_MODAL,
  show,
  onConfirm,
  onCancel,
  alertText,
});

export const showModal = (modalName, show = true) => ({
  type: SHOW_MODAL,
  modalName,
  show,
});
export const showSpinner = show => ({
  type: SPINNER,
  show,
});
export const viewSpinner = () => ({
  type: SPINNER,
  show: true,
});

export const hideSpinner = () => ({
  type: SPINNER,
  show: false,
});
