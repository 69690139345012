import { reset } from 'redux-form';
import { replace } from 'connected-react-router';

import Auth0Service from 'services/auth/Auth0Service';
import { toast } from 'react-toastify';
import { storeKeys, removeKeys } from '../utils/helpers/storageManager';

import AuthService from '../utils/services/AuthService';
import UserService from '../utils/services/UserService';

import { viewSpinner, hideSpinner } from './general';
// import { setAuthenticatedProfile } from './userActionCreators';

import {
  AUTH_START,
  AUTH_FAIL,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from './authenticationTypes';

export const logout = () => dispatch => {
  removeKeys(
    'token',
    'expirationDate',
    'userId',
    'email',
    'name',
    'partner',
    'profileId',
    'permissions',
    'referenceCode',
    'user_type',
    'role',
    'advisorId',
    'firstName',
    'lastName',
    'language',
    'createdAt',
    'updatedAt'
  );

  Auth0Service.logout();
  dispatch({ type: AUTH_LOGOUT });
  dispatch(replace('/login'));
};

export const checkAuthTimeout = expirationTime => dispatch => {
  setTimeout(() => {
    dispatch(logout());
  }, expirationTime * 1000);
};

export const authStart = () => ({
  type: AUTH_START,
});

export const authSuccess = token => {
  storeKeys({
    token,
    user_type: 'Administrador',
  });

  return {
    type: AUTH_SUCCESS,
    token,
  };
};

export const authFail = error => ({
  type: AUTH_FAIL,
  error,
});

// eslint-disable-next-line no-unused-vars
export const auth = (email, password) => dispatch => {
  const expiresIn = 36000;

  const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);

  localStorage.setItem('expirationDate', expirationDate);

  dispatch(authSuccess('token'));
};

export const authCheckState = () => dispatch => {
  const token = localStorage.getItem('token');
  if (!token) {
    dispatch(logout());
  } else {
    const expirationDate = new Date(localStorage.getItem('expirationDate'));
    if (expirationDate <= new Date()) {
      dispatch(logout());
    } else {
      const remainingTime =
        (expirationDate.getTime() - new Date().getTime()) / 1000;
      dispatch(checkAuthTimeout(remainingTime));
    }
  }
};

export const signUp = values => dispatch => {
  dispatch(viewSpinner());
  const { email, password } = values;
  AuthService.signUp(email, password)(
    res => {
      dispatch(viewSpinner());
      // console.log('res', res.data.data);
      const { userId } = res.data.data;
      const { name, partner } = values;
      UserService.createProfile(userId, email, name, partner)(
        () => {
          toast.success('Usuario creado satisfactoriamente!');
          dispatch(reset('create-account-form'));
        },
        () => {
          dispatch(hideSpinner());
        }
      );
    },
    () => {
      dispatch(hideSpinner());
    }
  );
};

export const sendRegistrationTokenTo = (name, email, appName) => dispatch => {
  dispatch(viewSpinner());
  AuthService.sendRegistrationToken(name, email, appName)(
    () => {
      toast.success('Solicitud enviada con exito!');
    },
    () => {
      dispatch(hideSpinner());
    },
    () => {
      toast.error('Something went wrong');
    }
  );
};
