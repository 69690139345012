export const notificationConfig = (
  title,
  message,
  status = 'success',
  dismissAfter = 5000,
  closeButton = false
) => ({
  title,
  message,
  status,
  dismissAfter,
  dismissible: true,
  position: 'tc',
  closeButton,
  allowHTML: true,
});

export const logException = (exception, logExtra = {}) => {
  const { REACT_APP_ENABLE_LOG_ROCKET: enableLog } = process.env;
  const { LogRocket } = window;
  if (enableLog === 'true' && LogRocket) {
    LogRocket.captureException(exception, {
      tags: {
        // additional data to be grouped as "tags"
        subscription: 'pro',
      },
      extra: {
        // additional arbitrary data associated with the event
        ...logExtra,
      },
    });
  }
};

export function formatFilterParams(params) {
  const paramString = Object.keys(params)
    .reduce((result, key) => {
      if (!params[key]) return result;

      const query = key.includes('~in')
        ? params[key]
            .split(',')
            .map((value = '') => value && `${key}=${encodeURIComponent(value)}`)
            .filter(x => x)
        : [`${key}=${encodeURIComponent(params[key])}`];

      return [...result, ...query];
    }, [])
    .join('&');

  return paramString && `?${paramString}`;
}

export const querySearchParse = search => {
  const params = {};
  if (!search) return params;

  const query = search.substring(1);

  query.split('&').forEach(s => {
    const [param, value] = s.split('=');
    params[param] = decodeURIComponent(value);
  });
  return params;
};

/**
 * Performs a look up with the values of first array, in the second array.
 * @param {Array} first First array to look up
 * @param {Array} second Second array to look up
 * @returns {boolean} True if any of the values in first array are in second array.
 */
export const isAnyOfFirstInSecond = (first, second) =>
  first.some(value => second.includes(value));

/**
 * Performs a look up with the values of first array, in the second array.
 * @param {Array} first First array to look up
 * @param {Array} second Second array to look up
 * @returns {boolean} True if all of the values in first array are in second array.
 */
export const isAllOfFirstInSecond = (first, second) =>
  first.every(value => second.includes(value));
