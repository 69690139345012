import React, { useContext, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SettingsContext from 'context/SettingsContext';
import getVersionTagByDino from 'services/api/version/getVersionDino';
import SidebarHeading from './SidebarHeading';
import Menu from './Menu';
import '../css/elements/left-sidebar-1.css';
import '../css/elements/collapsed-left-sidebar-1.css';

const versionApp = process.env.REACT_APP_APP_VERSION;

const LeftSidebar = ({ navigation }) => {
  const [versionTag, setVersionTag] = useState('v0.0.0');
  const {
    featureFlags: { featureFlagBanregio },
  } = useContext(SettingsContext);

  const featureFlagFilter = useCallback(
    item => {
      if (item.title === 'Direct Debit' && !featureFlagBanregio) {
        return false;
      }
      return true;
    },
    [featureFlagBanregio]
  );

  const fetchVersionApi = useCallback(async () => {
    const versionData = (
      await getVersionTagByDino()
    ).data.data;
    setVersionTag(versionData.versionApi);  
  }, []);

  useEffect(() => {
    fetchVersionApi();
  }, [fetchVersionApi]);

  return (
    <div>
      <div className="left-sidebar-placeholder" />
      <div className="left-sidebar left-sidebar-1">
        <div className="wrapper">
          <div className="content">
            <SidebarHeading />
            {navigation.map((menu, i) => (
              <div key={i} className="section">
                <div className="section-title">{menu.title}</div>
                <ul className="list-unstyled">
                  {menu.items.filter(featureFlagFilter).map((item, mi) => (
                    <Menu key={mi} items={item} />
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <p className='label-version-tag'>{versionApp || 'v0.0.0'}, powered by Dino {versionTag}</p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  navigation: state.navigation,
});
export default connect(mapStateToProps)(LeftSidebar);
